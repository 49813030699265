import type {
    AccountMessagingTemplates,
    AccountUser,
    AutomaticPaymentTransactionSubType,
    CommonApplicationState,
    CustomerNotificationMethodTypes,
    DistanceUnitType,
    Location,
    LocationSource,
    PaymentTransactionSubType,
    Service,
    StoredObject,
    SyncMode,
    Team,
    TemperatureUnitType,
    TransactionSubType,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AccountExpenseSettings,
    AccountInvoiceSettings,
    Customer,
    Frequency,
    FrequencyType,
    Invoice,
    Job,
    JobGroup,
    JobOccurrence,
    JobOccurrenceStatus,
    ProductLevel,
    Tag,
    TagType,
    Transaction,
    TransactionType,
    getDefaultLocationSource,
    notNullUndefinedEmptyOrZero,
    shouldLetThreadIn,
    ukPostcodeRegex,
    uuid,
    wait,
} from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-binding';
import type { Subscription } from 'aurelia-event-aggregator';
import moment from 'moment';
import vCard from 'vcf';
import { AccountActions } from '../Account/AccountActions';
import { presentSmsSettings } from '../Account/utils/presentSmsSettings';
import { ApplicationState } from '../ApplicationState';
import { AttachmentService } from '../Attachments/AttachmentService';
import { SelectAttachmentsDialog } from '../Attachments/Dialogs/SelectAttachmentsDialog';
import type { TImageAttachmentQuality } from '../Attachments/TImageAttachmentQuality';
import type { TImageAttachmentResolution } from '../Attachments/TImageAttachmentResolution';
import type { AllowedPaymentTypes } from '../Charge/PaymentMethods';
import { DefaultWorkerPaymentMethods, getPaymentMethods } from '../Charge/PaymentMethods';
import { AccountsLedgerService } from '../ChartOfAccounts/AccountsLedgerService';
import { AccountingPeriodSettingsDialog } from '../ChartOfAccounts/Components/AccountingPeriodSettingsDialog';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { ConnectedServicesDialog } from '../ConnectedServices/ConnectedServicesDialog';
import { ConnectedServicesService } from '../ConnectedServices/ConnectedServicesService';
import { ClientArchiveService } from '../Customers/ClientArchiveService';
import { CustomerService } from '../Customers/CustomerService';
import { AworkaImporter } from '../Data/AworkaImporter';
import { BackupImporter } from '../Data/BackupImporter';
import { CleanerPlannerImportResolver } from '../Data/CleanerPlannerImport/CleanerPlannerImportResolver';
import { CustomImporters } from '../Data/CustomParsers';
import { Data } from '../Data/Data';
import { GeorgeImporter } from '../Data/GeorgeImporter';
import { importFromGetSoapyCsv } from '../Data/GetSoapy/importFromGetSoapyCsv';
import { importFromGreenCleen } from '../Data/GreenCleenImporter';
import { SmartRoundImporter } from '../Data/SmartRoundImporter';
import { SqueegeeImporter } from '../Data/SqueegeeImporter';
import { createBackup } from '../Data/createBackup';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { CapacityMeasurement } from '../Dialogs/CapacityMeasurement/CapacityMeasurement';
import { CapacityMeasurementDialog } from '../Dialogs/CapacityMeasurement/CapacityMeasurementDialog';
import { CapacityMeasurementSavedMessage } from '../Dialogs/CapacityMeasurement/CapacityMeasurementMessage';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { FrequencyPickerDialog } from '../Dialogs/FrequencyPicker/FrequencyPickerDialog';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { Prompt } from '../Dialogs/Prompt';
import { prompt } from '../Dialogs/ReactDialogProvider';
import { Select } from '../Dialogs/Select';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { SlideGenerator } from '../Dialogs/SlideShow/SlideGenerator';
import { SmtpDialog } from '../Dialogs/SmtpDialog';
import { TextDialog } from '../Dialogs/TextDialog';
import { DurationDialog } from '../Dialogs/TimeOrDuration/DurationDialog';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { InvoiceService } from '../Invoices/InvoiceService';
import { DeveloperLaunchOptionsDialog } from '../Launch/DeveloperLaunchOptionsDialog';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import SettingsCannedResponsesDialog from '../ReactUI/canned-responses/SettingsCannedResponsesDialog';
import EmailSettingsDialog from '../ReactUI/email-settings/EmailSettingsDialog';
import ManageBackupsDialogComponent from '../ReactUI/settings/data/ManageBackupsDialogComponent';
import { Generator } from '../SampleData/Generator';
import { ScheduleService } from '../Schedule/ScheduleService';
import { Api } from '../Server/Api';
import { LocationApi } from '../Server/LocationApi';
import { SqueegeeTools } from '../SqueegeeTools';
import { TagService } from '../Tags/TagService';
import { ThemeService } from '../Theme/ThemeService';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { UserService } from '../Users/UserService';
import { Utilities, animate, validateEmailList } from '../Utilities';
import { isDevMode } from '../isDevMode';
import { t } from '../t';
import { MessageTemplates, bookingConfirmationTokenList } from './MessageTemplates';
import { generateSkipReasonsAndChargesText } from './generateSkipReasonsAndChargesText';
import { getDefaultSkipChargeText } from './getDefaultSkipChargeText';
import { setDefaultSkipJobCharge } from './setDefaultSkipJobCharge';
import { updateSkills } from './updateSkills';
import { updateSkipReasonsAndCharges } from './updateSkipReasonsAndCharges';

export class SettingsDialog extends CustomDialog<void> {
    protected value = 0;
    protected retrievingLocalPlaceDb = false;
    protected retrievingLocalPlaceDbStatus: string;
    protected localPlaceDbCount: number;
    protected isMobile = GlobalFlags.isMobile;
    protected messageTemplates = MessageTemplates.instance;
    protected account = ApplicationState.account;
    protected distanceUnits = ApplicationState.distanceUnits;
    protected temperatureUnits = ApplicationState.temperatureUnits;

    protected defaultJobDuration = this.account.defaultJobDuration;

    protected get invoiceGenEnabled() {
        return this.account.invoiceSettings.defaultInvoiceGenerationMethod !== 'manual';
    }
    protected set invoiceGenEnabled(value: boolean) {
        ApplicationState.account.invoiceSettings.defaultInvoiceGenerationMethod = value ? 'auto' : 'manual';
        this.save();
    }

    protected get autoInvoiceNotifyEnabled() {
        return this.account.invoiceSettings.defaultAutoInvoiceNotification === 'auto';
    }

    protected set autoInvoiceNotifyEnabled(value: boolean) {
        ApplicationState.account.invoiceSettings.defaultAutoInvoiceNotification = value ? 'auto' : 'manual';
        this.save();
    }

    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected hasUltimateOrAbove = ApplicationState.hasUltimateOrAbove;

    protected get hideStripePayButtonFromInvoice() {
        return !!this.account.invoiceSettings.hideStripePayButtonFromInvoice;
    }
    protected set hideStripePayButtonFromInvoice(value: boolean) {
        this.account.invoiceSettings.hideStripePayButtonFromInvoice = value;
        this.save();
    }

    protected get hideGoCardlessSignupFromInvoice() {
        return !!this.account.invoiceSettings.hideGoCardlessSignupFromInvoice;
    }
    protected set hideGoCardlessSignupFromInvoice(value: boolean) {
        this.account.invoiceSettings.hideGoCardlessSignupFromInvoice = value;
        this.save();
    }

    protected hasStripe = this.account.stripePublishableKey;
    protected hasGoCardless = this.account.goCardlessPublishableKey;
    protected multiUserEnabled = ApplicationState.multiUserEnabled;

    protected defaultNotificationMethod = ApplicationState.account.defaultNotificationMethod;
    protected defaultAutoPaymentCollection = ApplicationState.account.defaultAutoPaymentCollection;
    protected get preventMultiplePaymentsInSameDay() {
        return (
            (ApplicationState.account.paymentSettings && ApplicationState.account.paymentSettings.preventMultiplePaymentsInSameDay) || false
        );
    }
    protected set preventMultiplePaymentsInSameDay(value: boolean) {
        if (ApplicationState.account.paymentSettings) {
            ApplicationState.account.paymentSettings.preventMultiplePaymentsInSameDay = value;
            this.save();
        }
    }
    private _applicationStateUpdateSub: Subscription;
    protected capacityMeasurement = new CapacityMeasurement(
        ApplicationState.capacityMeasurementType,
        ApplicationState.capacityMeasurementBenchmark
    );
    protected workloadCapacityMeasurement = new CapacityMeasurement(
        ApplicationState.workloadCapacityMeasurementType,
        ApplicationState.workloadCapacityMeasurementBenchmark,
        ApplicationState.workloadCapacityAmount
    );
    protected stateFlags = ApplicationState.stateFlags;
    protected currentHostAndScheme = Api.currentHostAndScheme;
    protected cloudStorageUsed = AttachmentService.storageSpacedUsed();
    protected canUseAutoPayments = ApplicationState.hasAutomaticPaymentMethod();
    protected paymentPeriodText = ApplicationState.localise('settings.payment-period-text', {
        days: (ApplicationState.account.paymentPeriod || 0).toString(),
    });
    // 5gb
    protected cloudStorageAllowance = ApplicationState.hasUltimateOrAbove ? 53687063710 : 5368706371;
    imageAttachmentResolution: string;
    imageAttachmentQuality: string;
    _squeegeeCreditsNextRefresh: string;

    constructor() {
        super('settingsDialog', '../Settings/SettingsDialog.html', undefined, { okLabel: '', cancelLabel: '', isSecondaryView: true });
        this._init();
    }

    protected runTool() {
        SqueegeeTools.runTool();
    }

    static async openDialog() {
        await new SettingsDialog().show();
    }

    protected get defaultNotificationMethodKey(): TranslationKey {
        return !this.defaultNotificationMethod ? 'notification-methods.not-set' : `notification-methods.${this.defaultNotificationMethod}`;
    }

    private _compactClassDesktop = '';
    @computedFrom('_compactClassDesktop')
    protected get compactClassDesktop() {
        return this._compactClassDesktop;
    }
    private _compactClassMobile = '';
    @computedFrom('_compactClassMobile')
    protected get compactClassMobile() {
        return this._compactClassMobile;
    }

    protected zoomLevel(zoomLevel: number) {
        ApplicationState.setZoom(zoomLevel);
        localStorage.setItem('accessibility.zoom-level', zoomLevel.toString());
    }
    private async _init() {
        this._compactClassDesktop = ApplicationState.getSetting('theme.compact-menus-desktop', false) ? 'compact-desktop' : '';
        this._compactClassMobile = ApplicationState.getSetting('theme.compact-menus-mobile', false) ? 'compact-mobile' : '';

        await this.updateDatabaseSizeText();
        this.updateInvoiceNumber();

        const creditsUpdate = await Api.getSqueegeeCredits();
        this._squeegeeCredits = creditsUpdate?.squeegeeCredits;
        this._squeegeeCreditsNextRefresh = creditsUpdate?.nextRefresh;
        this._applicationStateUpdateSub = ApplicationStateUpdatedEvent.subscribe<ApplicationStateUpdatedEvent>(() => {
            delete this._businessLogo;
            this.businessLogoAsAvatar = ApplicationState.account.businessLogoAsAvatar;
        });

        this.imageAttachmentResolution = ApplicationState.getSetting<TImageAttachmentResolution>(
            'global.image-attachment-resolution',
            'resolution1440p'
        );

        this.imageAttachmentQuality = ApplicationState.getSetting<TImageAttachmentQuality>(
            'global.image-attachment-quality',
            'qualityHigh'
        );
    }

    protected async selectImageAttachmentResolution() {
        const options: Array<{ resolution: TImageAttachmentResolution }> = [
            { resolution: 'resolution720p' },
            { resolution: 'resolution1080p' },
            { resolution: 'resolution1440p' },
            { resolution: 'resolutionOriginal' },
        ];
        const imageAttachmentResolutionDialog = new Select(
            'attachments.image-attachment-resolution-select-title',
            options,
            'resolution',
            'resolution',
            this.imageAttachmentResolution
        );

        const imageAttachmentResolution = await imageAttachmentResolutionDialog.show();
        if (!imageAttachmentResolution || imageAttachmentResolutionDialog.cancelled) return;

        ApplicationState.setSetting('global.image-attachment-resolution', imageAttachmentResolution.resolution);
        this.imageAttachmentResolution = imageAttachmentResolution.resolution;
    }

    protected async selectImageAttachmentQuality() {
        const options: Array<{ quality: TImageAttachmentQuality }> = [
            { quality: 'qualityLow' },
            { quality: 'qualityMedium' },
            { quality: 'qualityHigh' },
        ];
        const imageAttachmentQualityDialog = new Select(
            'attachments.image-attachment-quality-select-title',
            options,
            'quality',
            'quality',
            this.imageAttachmentQuality
        );

        const imageAttachmentQuality = await imageAttachmentQualityDialog.show();
        if (!imageAttachmentQuality || imageAttachmentQualityDialog.cancelled) return;

        ApplicationState.setSetting('global.image-attachment-quality', imageAttachmentQuality.quality);
        this.imageAttachmentQuality = imageAttachmentQuality.quality;
    }

    public dispose() {
        this._applicationStateUpdateSub && this._applicationStateUpdateSub.dispose();
        super.dispose();
    }

    public save() {
        ApplicationState.save();
    }

    protected active: string | null;
    protected openSubMenu(active: string) {
        this.active = this.active === active ? null : active;
    }

    public async deleteAllData() {
        if (
            !(await new Prompt('general.confirm', 'delete.confirmation-all-records-from-account', {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
            }).show())
        )
            return;

        if (
            !(await new Prompt('general.confirm', 'delete.customer-count-and-final-confirm', {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
                localisationParams: { customerCount: Data.count<Customer>('customers').toString() },
            }).show())
        )
            return;
        new LoaderEvent(true);
        await Data.deleteAllData();
        new LoaderEvent(false);
        await new Prompt('general.complete', 'delete.confirmed-account-records', {
            okLabel: 'general.ok',
            cancelLabel: '',
        }).show();
        this.updateDatabaseSizeText();
    }

    protected marketplaceAlerts = ApplicationState.getSetting('global.marketplace-receive-area-alerts', true);
    protected refreshMarketplaceAreaAlerts = () =>
        animate(15).then(() => (this.marketplaceAlerts = ApplicationState.getSetting('global.marketplace-receive-area-alerts', true)));
    protected marketplaceAreaText = ApplicationState.localise('settings.marketplace-area-text', {
        distance: ApplicationState.getSetting('global.marketplace-receive-area', 15).toString(),
        unit: ApplicationState.distanceUnits,
    });

    protected async editMarketplaceArea() {
        const dialog = new TextDialog(
            'dialogs.settings-marketplace-area-title',
            t('dialogs.settings-marketplace-area-label', { unit: ApplicationState.distanceUnits }),
            ApplicationState.getSetting('global.marketplace-receive-area', 15).toString(),
            'dialogs.settings-marketplace-area-title',
            value =>
                isNaN(parseInt(value, 10)) || parseInt(value, 10) < 1 || parseInt(value, 10) > 250
                    ? t('validation.distance-invalid', { from: 1, to: 250 })
                    : true,
            false
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            const value = parseInt(result, 10);
            ApplicationState.setSetting('global.marketplace-receive-area', value);
            this.marketplaceAreaText = ApplicationState.localise('settings.marketplace-area-text', {
                distance: value.toString(),
                unit: ApplicationState.distanceUnits,
            });
        }
    }
    public async manageBackups() {
        AureliaReactComponentDialog.show({
            component: ManageBackupsDialogComponent,
            dialogTitle: 'settings.backup-manage-backups',
            componentProps: {},
            isSecondaryView: true,
        });
    }

    public async backupDatabaseAndEmail() {
        const confirmDialog = new Prompt('prompts.confirm-title', 'prompts.backup-message-with-email', {
            localisationParams: { email: ApplicationState.account.email },
        });
        await confirmDialog.show();
        if (!confirmDialog.cancelled) {
            await createBackup();
        }
    }

    public checkIfTheyAreSureTheyWantToToggleArchivingOfJobOccurrences = async (enabled: boolean) => {
        try {
            if (!Api.isConnected) {
                new NotifyUserMessage('archive.disable-auto-arhiving-job-occurrences-online-error');
                return false;
            }

            let message: TranslationKey;
            if (enabled) message = 'archive.enable-auto-arhiving-job-occurrences-confirm';
            else message = 'archive.disable-auto-arhiving-job-occurrences-confirm';

            const goAhead = await Prompt.continue(message);
            if (!goAhead) return false;

            if (!enabled) {
                const succeeded = await ClientArchiveService.unarchiveAllJobOccurrences();
                if (!succeeded) {
                    new NotifyUserMessage('archive.disable-auto-arhiving-job-occurrences-error');
                    return false;
                }
            }

            return true;
        } catch (error) {
            Logger.error('Unable to toggle the auto archiving of occurrences.', error);
            return false;
        }
    };

    protected async forceFullSync(mode: SyncMode) {
        await Data.fullSync(mode);
    }

    public async unarchiveAllTransactions() {
        await ClientArchiveService.unarchiveAllTransactions();
    }

    public async archiveTransactionsOnOrBeforeDate() {
        await ClientArchiveService.archiveTransactionsOnOrBeforeDate();
    }

    public async archiveStoredEventsOnOrBeforeDate() {
        await ClientArchiveService.archiveStoredEventsOnOrBeforeDate();
    }

    public async unarchiveAllStoredEvents() {
        await ClientArchiveService.unarchiveAllStoredEvents();
    }

    public async archiveNotificationsOnOrBeforeDate() {
        await ClientArchiveService.archiveNotificationsOnOrBeforeDate();
    }

    public async unarchiveAllJobOccurrences() {
        await ClientArchiveService.unarchiveAllJobOccurrences();
    }

    public async archiveJobOccurrencesOnOrBeforeDate() {
        await ClientArchiveService.archiveJobOccurrencesOnOrBeforeDate();
    }

    public async deleteTransactionsBeforeDate() {
        const datePicker = new DateTimePicker(false, undefined, undefined, false);
        datePicker.init();
        await datePicker.open();

        if (!datePicker.canceled) {
            const date = moment(datePicker.selectedDate);

            const youAreSure = await new Prompt(
                'general.confirm',
                (`Are you sure you wish to delete all financial transactions including payments, ` +
                    `invoices, credit notes etc for all customers on or before ${date.format('ll')}?`) as TranslationKey,
                {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                }
            ).show();

            if (youAreSure) {
                const transactionsToDelete = Data.all<Transaction>('transactions', t => moment(t.date).isSameOrBefore(date, 'day')).slice();

                await Data.delete(transactionsToDelete);
                new LoaderEvent(false);
                const description = `All transactions for all customers on or before ${date.format('ll')} have been deleted.`;
                await new Prompt('general.complete', description as TranslationKey, { okLabel: 'general.ok', cancelLabel: '' }).show();
            }
        }
    }

    protected isOwner = ApplicationState.isInAnyRole(['Owner']);

    public async importDataFromAnotherAccount() {
        const anonymise = await new Prompt('Anonymise' as TranslationKey, 'Do you want to anonymise the data?' as TranslationKey, {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        }).show();

        const dialog = new FileUploadDialog([''], undefined);

        const files = await dialog.show();
        if (files && files.length) {
            const file = files[0];
            const reader = new FileReader();
            try {
                const loader = new Promise<void>(resolve => (reader.onload = () => resolve()));
                reader.readAsText(file);
                await loader;
                const jsonAll = <string>reader.result;
                let importData: Array<StoredObject> = JSON.parse(jsonAll);

                new LoaderEvent(true);
                const jsons: Array<string> = [];
                const ids: { [id: string]: string } = {};
                for (const o of importData) {
                    if (shouldLetThreadIn(o, importData, 100)) {
                        new LoaderEvent(true, undefined, 'loader.data-object-ids-identified', undefined, {
                            data: (importData.indexOf(o) + 1).toString(),
                        });
                        await wait(1);
                    }
                    const json = JSON.stringify(o);
                    // WTF Replace all IDs with newly generated ones.
                    Object.assign(
                        ids,
                        (json.match(/"\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\S*?"/g) || [])
                            .filter((id, index, array) => index === array.indexOf(id))
                            .reduce<{ [id: string]: string }>((dictionary, next) => {
                                dictionary[next] = `"${uuid()}${next.substring(37)}`;
                                return dictionary;
                            }, {})
                    );
                    jsons.push(json);
                }

                importData = [];
                await wait(250);
                let count = 0;
                for (let json of jsons) {
                    if (count > 0 && count % 100 === 0) {
                        new LoaderEvent(true, undefined, 'loader.count-object-ids-updated', undefined, { count: count.toString() });
                        await wait(1);
                    }
                    json = json.replace(/"\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\S*?"/g, original => ids[original]);
                    importData.push(JSON.parse(json));
                    count++;
                }
                await wait(50);

                new LoaderEvent(false);

                if (!(await createBackup())) return;
                const deleteExistingData = await new Prompt(
                    'Delete Existing Data?' as TranslationKey,
                    'Would you like to delete the existing data?' as TranslationKey,
                    {
                        okLabel: 'menubar.delete',
                        cancelLabel: 'general.no',
                    }
                ).show();

                await BackupImporter.restore(importData, false, true, deleteExistingData, anonymise);
            } catch (error) {
                Logger.info('import failed', error);
                new Prompt('prompts.error-title', 'prompts.restore-error-text', { cancelLabel: '' }).show();
            } finally {
                new LoaderEvent(false);
            }
        }
    }

    private _theme?: string;
    public get theme() {
        if (!this._theme) this._theme = ApplicationState.getSetting('global.theme', 'standard');
        return this._theme;
    }
    public switchTheme = (theme: Parameters<typeof ThemeService.switchTheme>[0]) => {
        ThemeService.switchTheme(theme);
        delete this._theme;
    };

    public createCustomTheme = ThemeService.createCustomTheme;

    public async importDatabaseFromEmailBackup() {
        if (
            (GlobalFlags.isDevServer || isDevMode()) &&
            !(await new Prompt(
                'settings.import-database',
                'Where does the data you are importing originate (which email account)?' as TranslationKey,
                {
                    okLabel: 'This Account' as TranslationKey,
                    cancelLabel: 'Another User' as TranslationKey,
                }
            ).show())
        ) {
            return this.importDataFromAnotherAccount();
        }

        const dialog = new FileUploadDialog([''], undefined);
        const files = await dialog.show();
        if (files && files.length) {
            const file = files[0];
            const reader = new FileReader();
            try {
                const loader = new Promise<void>(resolve => (reader.onload = () => resolve()));
                reader.readAsText(file);
                await loader;
                const dbData = JSON.parse(<string>reader.result);

                const areYouSure = new Prompt('prompts.confirm-title', 'prompts.confirm-restore-text', {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                });
                const youAreSure = await areYouSure.show();
                if (youAreSure) {
                    if (!(await createBackup())) return;
                    const complete = new Prompt('prompts.restoring-title', 'prompts.restore-complete-text', { cancelLabel: '' });
                    await complete.load(BackupImporter.restore(dbData, false));
                    if (complete.cancelled) {
                        new Prompt('prompts.error-title', 'prompts.restore-error-text', { cancelLabel: '' }).show();
                    }
                }
            } catch (error) {
                new NotifyUserMessage('notifications.import-unreadable');
                Logger.error('Error in importDatabaseFromEmailBackup() on SettingsDialog', { files, error });
            }
        }
    }

    protected async generateMockBackupData() {
        Api.post(null, '/api/backups/generate-mock-history');
    }

    protected importFromGetSoapyCsv = async () => {
        await importFromGetSoapyCsv();
    };

    public async importVCF() {
        const dialog = new FileUploadDialog([''], undefined);
        const files = await dialog.show();
        if (files && files.length && files.length === 1) {
            const file = files[0];
            const reader = new FileReader();
            try {
                const loader = new Promise<void>(resolve => (reader.onload = () => resolve()));
                reader.readAsText(file);
                await loader;

                let text = reader.result as string;
                text = text
                    .replace(/\r\n/g, '\n')
                    .replace(/\n/g, '\r\n')
                    .replace(/=\r\n=/g, '=');
                const contacts = vCard.parse(text);
                if (!contacts.length) return new Prompt('prompts.error-title', 'No contacts found in vCard file' as TranslationKey);

                const useNamesAsAddresses = await new Prompt(
                    'Use Names as Addresses' as TranslationKey,
                    'Do you wish to use the contact name field as the address in Squeegee?' as TranslationKey,
                    { okLabel: 'general.yes', cancelLabel: 'general.no' }
                ).show();

                const customers = [] as Array<Customer>;
                for (const contact of contacts) {
                    const name = this.getCVcfPropertyValues(contact, 'fn').join(' ').replace(/;/g, ' ').trim();
                    const email = this.getCVcfPropertyValues(contact, 'email').join(',').replace(/;/g, ' ').trim();
                    const tels = this.getCVcfPropertyValues(contact, 'tel');
                    const telephoneNumber = (tels[0] || '').replace(/;/g, ' ').trim();
                    const telephoneNumberOther = (tels[1] || '').replace(/;/g, ' ').trim();
                    const addressDescription = useNamesAsAddresses
                        ? name
                        : (this.getCVcfPropertyValues(contact, 'adr')[0] || 'Unknown').replace(/;/g, ' ').trim();

                    const customer = new Customer(name);
                    customer.email = email;
                    customer.telephoneNumber = telephoneNumber;
                    customer.telephoneNumberOther = telephoneNumberOther;
                    customer.address.addressDescription = addressDescription;

                    customers.push(customer);
                }

                if (!customers.length) return new Prompt('prompts.error-title', 'No customers found in vCard file' as TranslationKey);

                const description = `Go ahead and import ${customers.length} customer${customers.length > 1 ? 's' : ''} from VCF file?`;
                const areYouSure = new Prompt('general.confirm', description as TranslationKey, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                });

                const youAreSure = await areYouSure.show();
                if (youAreSure) {
                    Data.put(customers);
                }
            } catch (error) {
                new NotifyUserMessage('notifications.import-unreadable');
                Logger.error('Error in importDatabaseFromEmailBackup() on SettingsDialog', { files, error });
            }
        }
    }

    private getCVcfPropertyValues(contact: vCard, propertyName: 'fn' | 'email' | 'tel' | 'adr'): Array<string> {
        if (!contact || !contact.data || !contact.data[propertyName]) return [];
        const propertyConfig = contact.data[propertyName];
        const properties = (Array.isArray(propertyConfig) ? propertyConfig : [propertyConfig]) as Array<
            vCard.Property & { encoding: string | 'QUOTED-PRINTABLE' }
        >;
        return properties.map(p => {
            let value = p.valueOf();
            if (p.encoding === 'QUOTED-PRINTABLE') {
                const encoded = value
                    .replace(/=/g, '%')
                    .replace(/%$/, '')
                    .replace(/%20%C2$/g, '')
                    .replace(/%2F%C2$/g, '');
                try {
                    value = decodeURIComponent(encoded);
                } catch (error) {
                    Logger.info(`Can't decode value for ${value} (${encoded})`);
                }
            }
            return value;
        });
    }

    protected applicationState = ApplicationState;
    @computedFrom('applicationState.stateFlags.devMode')
    protected get isDevMode() {
        return ApplicationState.stateFlags.devMode;
    }

    private _businessLogo?: string;

    protected get businessLogo() {
        if (this._businessLogo === undefined) {
            if (ApplicationState.account.businessLogo) {
                this._businessLogo = ApplicationState.account.businessLogo;
            } else {
                this._businessLogo = '';
            }
        }
        return this._businessLogo;
    }

    public async uploadLogo() {
        if (ApplicationState.account.businessLogo) {
            const replace = await new Prompt(
                'Replace or Remove Logo' as TranslationKey,
                'Do you want to replace or remove the current logo?' as TranslationKey,
                {
                    okLabel: 'replace' as TranslationKey,
                    cancelLabel: 'remove' as TranslationKey,
                }
            ).show();

            if (!replace) {
                if (
                    await Prompt.continue(
                        'Are you sure you want to remove the current logo, you will need to original file to undo this?' as TranslationKey
                    )
                ) {
                    ApplicationState.account.businessLogo = '';
                    await ApplicationState.save();
                    delete this._businessLogo;
                }
                return;
            }
        }

        await ApplicationState.setBusinessLogo();
    }

    protected locationSource: LocationSource = ApplicationState.getSetting(
        'global.location-source',
        getDefaultLocationSource(ApplicationState.account.language.slice(-2))
    );
    protected locationSourceName =
        this.locationSource === 'here'
            ? 'HERE'
            : this.locationSource === 'google'
            ? 'Google'
            : ApplicationState.account.language.endsWith('GB')
            ? 'GetAddress'
            : 'Loqate';
    protected changeLocationSource = async () => {
        const selectLocationSource = new Select<{ text: string; value: LocationSource }>(
            'settings.select-location-source',
            [
                { value: 'google', text: 'Google' },
                { value: 'addressService', text: ApplicationState.account.language.endsWith('GB') ? 'GetAddress' : 'Loqate' },
                { value: 'here', text: 'HERE' },
            ],
            'text',
            'value',
            this.locationSource
        );

        const newLocationSource = await selectLocationSource.show();
        if (selectLocationSource.cancelled) return;

        ApplicationState.setSetting('global.location-source', newLocationSource.value);
        this.locationSource = newLocationSource.value;
        this.locationSourceName = this.locationSource === 'here' ? 'HERE' : this.locationSource === 'google' ? 'Google' : 'Loqate';
    };

    protected get businessLogoAsAvatar() {
        return ApplicationState.account.businessLogoAsAvatar;
    }
    protected set businessLogoAsAvatar(value: boolean) {
        const businessLogoAsAvatar = (this.businessLogo && value) || false;
        if (businessLogoAsAvatar !== ApplicationState.account.businessLogoAsAvatar) {
            ApplicationState.account.businessLogoAsAvatar = value;
            ApplicationState.save();
        }
    }

    public async importCleanerPlannerFullSet() {
        let importResolver: CleanerPlannerImportResolver | undefined;
        try {
            const dialog = new FileUploadDialog(['.zip'], 'uploads.cleaner-planner-zip');
            const files = await dialog.show();
            if (dialog.cancelled) return;
            new LoaderEvent(true);
            if (files && files.length === 1) importResolver = await CleanerPlannerImportResolver.createImportFromZip(files[0]);
        } catch (error) {
            new LoaderEvent(false);
            await new Prompt('general.warning', <TranslationKey>error, { okLabel: 'general.ok', cancelLabel: '' }).show();
            return;
        }
        new LoaderEvent(false);

        if (!importResolver) return;

        const deleteExistingData = await new Prompt('prompts.delete-existing-data-title', 'prompts.delete-existing-data-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        }).show();

        await importResolver.doFullImport(deleteExistingData);

        await importResolver.doHistoricImport;
    }

    public async historicImport() {
        let importResolver: CleanerPlannerImportResolver | undefined;
        try {
            const dialog = new FileUploadDialog(['.zip'], 'uploads.cleaner-planner-zip');
            const files = await dialog.show();
            if (dialog.cancelled) return;
            new LoaderEvent(true);
            if (files && files.length === 1) importResolver = await CleanerPlannerImportResolver.createImportFromZip(files[0]);
        } catch (error) {
            new LoaderEvent(false);
            await new Prompt('general.warning', <TranslationKey>error, { okLabel: 'general.ok', cancelLabel: '' }).show();
            return;
        }
        new LoaderEvent(false);

        if (!importResolver) return;

        const datePickerDialog = new DateTimePicker(false);
        datePickerDialog.init();
        datePickerDialog.title = 'Select cut off date' as TranslationKey;
        await datePickerDialog.open();
        if (datePickerDialog.canceled) return;

        const before = datePickerDialog.selectedDate;
        if (!before) return;

        await importResolver.doHistoricImport(before);
    }
    private _defaultJobFrequencyText?: string;
    protected get defaultJobFrequencyText() {
        if (!this._defaultJobFrequencyText) {
            if (ApplicationState.defaultJobFrequency) {
                const defaults = ApplicationState.defaultJobFrequency;
                const frequency = new Frequency(
                    moment(),
                    defaults.interval,
                    defaults.type,
                    defaults.weekOfMonth,
                    defaults.dayOfMonth,
                    defaults.dayOfWeek
                );
                const localisation = frequency.defaultLocalisationKeyAndParams;
                this._defaultJobFrequencyText = ApplicationState.localise(localisation.key, localisation.params);
            } else this._defaultJobFrequencyText = '-';
        }
        return this._defaultJobFrequencyText;
    }

    public async viewApiKeys() {
        const result = await Api.get<Array<string>>(null, '/api/authentication/apikeys');
        const apiKeys = result && result.data;
        if (!apiKeys) {
            new Prompt('api.keys-no-keys', 'api.keys-no-current-keys', {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
        } else {
            const select = new Select(
                'api.key-select',
                apiKeys.map(x => {
                    return { text: <TranslationKey>x, value: x };
                }),
                'text',
                'value'
            );
            select.disableLocalisation = true;
            const apiKey = await select.show();
            if (!select.cancelled) {
                // WTF: This is not localisable.
                const prompt = new Prompt('api.key-general', <TranslationKey>apiKey.value, {
                    okLabel: 'general.copy',
                    cancelLabel: 'general.cancel',
                    altLabel: 'general.delete',
                });
                const result = await prompt.show();
                if (!prompt.cancelled) {
                    if (!result) {
                        if (
                            await new Prompt('general.delete', 'api.key-wish-to-delete', {
                                okLabel: 'general.delete',
                                cancelLabel: 'general.cancel',
                            }).show()
                        ) {
                            await Api.delete(null, `/api/authentication/apikey/${apiKey.value}`);
                        }
                    } else {
                        new TextDialog(
                            'api.key-copy-key',
                            'api.key-copy-from-text-box-below',
                            apiKey.value,
                            '',
                            undefined,
                            false,
                            'text',
                            'general.ok'
                        ).show();
                    }
                }
            }
        }
    }

    public async generateApiKey() {
        const keyDialog = new TextDialog(
            'api.key-prefix',
            'api.key-enter-eight-character-prefix-for-identification',
            'SQUEEGEE',
            '',
            value => (value.length > 12 || !/^[a-zA-Z0-9-]*?$/.test(value) ? 'api.key-prefix-must-be-12-characters-or-less' : true),
            false,
            'text',
            'general.ok'
        );

        const prefix = await keyDialog.show();

        if (!keyDialog.cancelled) {
            const result = await Api.post<{ apiKey: string }>(null, '/api/authentication/apikey', { prefix });
            if (result && result.data && result.data.apiKey) {
                new TextDialog(
                    'api.key-general',
                    'api.key-copy-new-below',
                    result.data.apiKey,
                    '',
                    undefined,
                    false,
                    'text',
                    'general.ok'
                ).show();
            } else {
                new Prompt('general.warning', 'problem.creating-api-key-contact-sqg-support', {
                    okLabel: 'general.ok',
                    cancelLabel: '',
                }).show();
            }
        }
    }

    protected async setDefaultJobFrequency() {
        const dialog = new FrequencyPickerDialog(
            ApplicationState.defaultJobFrequency,
            true,
            true,
            false,
            'frequency',
            undefined,
            undefined,
            false
        );
        const frequencyResult = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            ApplicationState.defaultJobFrequency = frequencyResult;
            delete this._defaultJobFrequencyText;
            ApplicationState.save();
        }
    }

    protected defaultAssignee = this.getDefaultAssigneeText();
    private getDefaultAssigneeText() {
        if (!ApplicationState.account.defaultAssignee) return;
        const team = Data.get<Team>(ApplicationState.account.defaultAssignee);
        if (team) return team.name;

        const accountUser = UserService.getUser(ApplicationState.account.defaultAssignee);
        if (accountUser) return `${accountUser.name} (${accountUser.email})`;
    }

    protected async setDefaultAssignee() {
        await ScheduleService.setDefaultAssignee(true);
        const userOrTeam = AssignmentService.getDefaultAssigneeUserOrTeam(true);

        this.defaultAssignee =
            !userOrTeam || !userOrTeam._id
                ? undefined
                : userOrTeam.resourceType === 'team'
                ? `${userOrTeam.name}`
                : `${(userOrTeam as AccountUser).name} (${(userOrTeam as AccountUser).email})`;
    }

    public async importCSV(type: 'George' | 'Squeegee' | 'Aworka' | 'Cleaner Planner' | 'SmartRound' | 'Cleaner Planner Full') {
        const dialog = new FileUploadDialog(type === 'Squeegee' ? ['.csv', '.json'] : ['csv'], 'uploads.csv-import-file');
        const files = await dialog.show();
        if (files && files.length) {
            const file = files[0];
            try {
                const deleteExisting = new Prompt('prompts.delete-existing-data-title', 'prompts.delete-existing-data-text', {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                });
                const deleteExistingData = type !== 'Squeegee' && (await deleteExisting.show());

                new LoaderEvent(true);

                const applicationState: CommonApplicationState = Utilities.copyObject(ApplicationState.instance);

                let importedOutput: { warnings?: Array<string>; error: string; success: boolean } = { error: '', success: false };

                const fileContent = await Utilities.readFileAsString(file);

                switch (type) {
                    case 'George':
                        importedOutput = await GeorgeImporter.import(fileContent, deleteExistingData);
                        break;
                    case 'Squeegee':
                        importedOutput = await SqueegeeImporter.import(
                            fileContent,
                            file.name.endsWith('json') ? 'json' : 'csv',
                            deleteExistingData
                        );
                        break;
                    case 'Aworka':
                        importedOutput = await AworkaImporter.import(fileContent, deleteExistingData);
                        break;
                    case 'SmartRound':
                        importedOutput = await SmartRoundImporter.import(fileContent, deleteExistingData);
                        break;
                }

                if (deleteExistingData && importedOutput.success) {
                    await Data.put(applicationState);
                }

                let html = '';

                if (!importedOutput.success) {
                    html += `${ApplicationState.localise('prompts.import-complete-error-text')}<br>${importedOutput.error
                        .slice(0, 5000)
                        .replace(/\n/g, '<br>')}`;
                } else {
                    html = `${ApplicationState.localise('prompts.import-complete-text')}`;
                }

                if (importedOutput.warnings && importedOutput.warnings.length) {
                    html += `<br>${ApplicationState.localise('prompts.import-complete-warnings-text')}<br>${importedOutput.warnings.join(
                        '<br>'
                    )}`;
                }

                new LoaderEvent(false);
                new Prompt(importedOutput.success ? 'prompts.success-title' : 'prompts.error-title', <any>html, { cancelLabel: '' }).show();
            } catch (error) {
                new LoaderEvent(false);
                new Prompt('prompts.error-title', 'prompts.import-error-text', { cancelLabel: '' }).show();
                Logger.error('Error in importCsv() on SettingsDialog', { type, error });
            }
        }
    }

    public async deleteDatabase() {
        try {
            const prompt = new Prompt('prompts.confirm-title', 'prompts.delete-all-data-confirm-text', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            });
            await prompt.show();
            if (!prompt.cancelled) {
                const includingAccountUsers = await new Prompt('general.confirm', 'delete.any-account-users', {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                }).show();

                await Data.deleteAllData(includingAccountUsers);
                document.location && document.location.reload();
            }
        } catch (error) {
            Logger.error('Error in deleteDatabase() on SettingsDialog', error);
        }
    }

    protected async editPaymentPeriod() {
        const dialog = new TextDialog(
            'dialogs.settings-payment-period-title',
            'dialogs.settings-payment-period-label',
            (ApplicationState.account.paymentPeriod || 0).toString(),
            'dialogs.settings-payment-period-title',
            value =>
                isNaN(parseInt(value, 10)) || parseInt(value, 10) < 0 || parseInt(value, 10) > 1000
                    ? 'validation.payment-period-invalid'
                    : true,
            false,
            'number'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.paymentPeriod = parseInt(result, 10);
            ApplicationState.save();
            this.paymentPeriodText = ApplicationState.localise('settings.payment-period-text', {
                days: (ApplicationState.account.paymentPeriod || 0).toString(),
            });
        }
    }

    public async selectDistanceUnits() {
        const select = new Select(
            'dialogs.distance-title',
            [
                { text: ApplicationState.localise('general.miles'), value: 'miles' },
                { text: ApplicationState.localise('general.kilometres'), value: 'kilometres' },
            ],
            'text',
            'value',
            this.distanceUnits
        );
        const result = await select.show(DialogAnimation.SLIDE);
        if (!select.cancelled) {
            this.distanceUnits = <DistanceUnitType>result.value;
            ApplicationState.distanceUnits = <DistanceUnitType>result.value;
            ApplicationState.save();
        }
    }

    public async selectTemperatureUnits() {
        const select = new Select(
            'dialogs.temperature-title',
            [
                { text: ApplicationState.localise('general.celsius'), value: 'celsius' },
                { text: ApplicationState.localise('general.fahrenheit'), value: 'fahrenheit' },
            ],
            'text',
            'value',
            this.temperatureUnits
        );
        const result = await select.show(DialogAnimation.SLIDE);
        if (!select.cancelled) {
            this.temperatureUnits = <TemperatureUnitType>result.value;
            ApplicationState.temperatureUnits = <TemperatureUnitType>result.value;
            ApplicationState.save();
        }
    }

    public async selectWorkPlannerCapacity() {
        const select = new CapacityMeasurementDialog(Utilities.copyObject(this.capacityMeasurement), {
            titleLocale: 'dialogs.work-planner-settings-title',
            benchmarkLocale: 'settings.work-planner-capacity-benchmark',
            typeLocale: 'settings.work-planner-capacity-type',
        });
        const result = await select.show(GlobalFlags.isHttp ? DialogAnimation.GROW : DialogAnimation.SLIDE);
        if (!select.cancelled) {
            this.capacityMeasurement.benchmark = result.benchmark;
            this.capacityMeasurement.type = result.type;
            ApplicationState.capacityMeasurementBenchmark = result.benchmark;
            ApplicationState.capacityMeasurementType = result.type;
            await ApplicationState.save();
            new CapacityMeasurementSavedMessage(this.capacityMeasurement);
        }
    }

    public async selectWorkloadCapacity() {
        const select = new CapacityMeasurementDialog(Utilities.copyObject(this.workloadCapacityMeasurement), {
            typeLocale: 'settings.work-load-capacity-type',
            amountLocale: 'settings.work-load-capacity-amount',
            titleLocale: 'settings.work-load-capacity-title',
        });
        const result = await select.show(GlobalFlags.isHttp ? DialogAnimation.GROW : DialogAnimation.SLIDE);
        if (!select.cancelled) {
            this.workloadCapacityMeasurement.type = result.type;
            this.workloadCapacityMeasurement.amount = result.amount;
            ApplicationState.workloadCapacityAmount = result.amount || 30;
            ApplicationState.workloadCapacityMeasurementType = result.type;
            await ApplicationState.save();
            new CapacityMeasurementSavedMessage(this.workloadCapacityMeasurement);
        }
    }

    public async setCustomProfileImage() {
        const attachmentDialog = new SelectAttachmentsDialog(undefined, { selectOne: true });
        const attachment = (await attachmentDialog.show(DialogAnimation.SLIDE))?.[0];
        if (attachmentDialog.cancelled) return;

        if (!attachment.mimeType?.startsWith('image'))
            return new NotifyUserMessage('You must select an image attachment.' as TranslationKey);

        if (!attachment.isPublic) return new NotifyUserMessage('The image must have public access enabled.' as TranslationKey);

        const url = AttachmentService.getPublicUrl(attachment);

        ApplicationState.setSetting('global.profile-custom-image-url', url);

        ApplicationState.updateProfileImagePath();
    }

    protected async bulkValidateAddresses() {
        let confirm = true;
        if (ApplicationState.stateFlags.devMode) {
            const confirmDialog = await new Prompt(
                'dialogs.auto-or-manual-address-validation-title',
                'dialogs.auto-or-manual-address-validation-description',
                {
                    okLabel: 'dialogs.auto-or-manual-address-validation-ok',
                    altLabel: 'dialogs.auto-or-manual-address-validation-cancel',
                    cancelLabel: 'general.cancel',
                }
            );
            confirm = await confirmDialog.show();

            if (confirmDialog.cancelled) return;
        }

        if (!confirm && !(await Prompt.continue('dialogs.confirm-auto-validate-addresses'))) return;

        const useOriginalAddressText = await new Prompt(
            'Use Original Address Text' as TranslationKey,
            'Would you like to use the original address text when updating each address rather than the address provided by the address lookup service?' as TranslationKey,
            { okLabel: 'Original Text' as TranslationKey, cancelLabel: 'Updated Text' as TranslationKey }
        ).show();

        if (confirm)
            await new Prompt('general.information', 'dialogs.auto-or-manual-address-validation-select-description', {
                cancelLabel: '',
            }).show();

        const activeOnly = await new Prompt('general.confirm', 'Validate addresses for active customers only?' as TranslationKey, {
            okLabel: 'Active Only' as TranslationKey,
            cancelLabel: 'All Customers' as TranslationKey,
        }).show();

        new LoaderEvent(true, true, 'loader.validating-addresses');
        let count = 1;
        try {
            const customers = CustomerService.getCustomers().filter(
                c => c.address?.addressDescription && !c.address.isVerified && (!activeOnly || (c.state || 'active') === 'active')
            );

            const updates: StoredObject[] = [];

            for (const customer of customers) {
                if (!customer.address.addressDescription) continue;

                await animate();
                const searchAddress = customer.address.addressDescription.replace(ukPostcodeRegex, '').replace(/, ?$/, '');
                const originalAddress = customer.address.addressDescription;
                const locations = await LocationApi.getAddresses(searchAddress);
                if (!locations.length) continue;

                let location: Location | undefined;
                if (confirm || locations.length > 1) {
                    new LoaderEvent(false);
                    const options = locations.map(x => ({ text: x.addressDescription }));
                    options.push({ text: ApplicationState.localise('dialogs.auto-or-manual-address-validation-select-no-match') });
                    const title = `${customer.address.addressDescription} (${customer.name})`;
                    const select = new Select(title as TranslationKey, options, 'text', 'text');
                    const selected = await select.show();
                    if (select.cancelled) return;
                    location = locations.find(x => x.addressDescription === selected.text);
                    if (!location) continue;
                    new LoaderEvent(true, true, 'general.searching-dot');
                } else {
                    location = locations[0];
                }

                if (location.addressId) {
                    if (location.addressSource === 'addressService') {
                        const lngLat = await LocationApi.getLngLatForLoqateAddress(location.addressId);
                        if (lngLat) {
                            location.isVerified = true;
                            location.lngLat = lngLat;
                        }
                    } else if (location.addressSource === 'getAddress') {
                        const updatedLocation = await LocationApi.getDetailsForGetAddressId(location.addressId);
                        if (updatedLocation) Object.assign(location, updatedLocation);
                    }
                }

                customer.address = location;
                if (useOriginalAddressText) customer.address.addressDescription = originalAddress;

                for (const id in customer.jobs || {}) {
                    const job = customer.jobs[id];
                    if (job.location && job.location.addressDescription === originalAddress) {
                        job.location = Utilities.copyObject(customer.address);
                        for (const jobOccurrence of Data.all<JobOccurrence>('joboccurrences', {
                            jobId: job._id,
                            status: JobOccurrenceStatus.NotDone,
                        })) {
                            jobOccurrence.location = job.location;
                            if (!confirm) updates.push(jobOccurrence);
                            else await Data.put(jobOccurrence);
                        }
                    }
                }

                if (!confirm) updates.push(customer);
                else await Data.put(customer);
                new LoaderEvent(true, true, 'loader.validated-count-customer-addresses', undefined, {
                    count: count.toString(),
                    total: customers.length.toString(),
                });
                count++;
            }
            if (!confirm) await Data.put(updates);
            new LoaderEvent(false);
            const description = `Updated ${updates.length} customers.`;
            new Prompt('general.complete', description as TranslationKey);
        } catch (error) {
            new Prompt('general.warning', 'fail.to-create-test-data');
            new LoaderEvent(false);
        }
    }

    protected get signatureHtml() {
        return (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailSignatureHtml) || '';
    }

    protected async setEmailSignatureHtml() {
        const editor = new TextDialog(
            'Edit Email Signature Html' as TranslationKey,
            '',
            (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailSignatureHtml) || '',
            '',
            undefined,
            false,
            'tinymce',
            'general.save',
            undefined,
            undefined,
            undefined,
            false
        );
        const result = await editor.show();
        if (!editor.cancelled) {
            ApplicationState.messagingTemplates.emailSignatureHtml = result;
            ApplicationState.save();
        }
    }

    public async addSampleCustomers() {
        try {
            const howManyPrompt = new TextDialog(
                <TranslationKey>'How many customers?',
                '',
                '50',
                '',
                n => (Number(n) ? true : <TranslationKey>'must be a number'),
                false,
                'number',
                'general.ok'
            );
            let count = Number(await howManyPrompt.show()) || 0;

            const contactPhone = ApplicationState.account.contactNumber || '';
            const phone =
                (await new TextDialog(
                    'Phone Number' as TranslationKey,
                    'Enter a phone number to use for these customers' as TranslationKey,
                    contactPhone,
                    ''
                ).show()) || '';

            const emailParts = ApplicationState.userAuthorisation.userEmail.split('@');
            const email =
                (await new TextDialog(
                    'Email Address' as TranslationKey,
                    'Enter an email address to use for these customers. Keys available: [customerId] / [customerName]' as TranslationKey,
                    emailParts[0] + '+[customerId]@' + emailParts[1],
                    ''
                ).show()) || '';
            const today = moment();
            const todayIso = today.format('YYYY-MM-DD');

            if (howManyPrompt.cancelled || count <= 0 || count > 1000000) return;

            new LoaderEvent(true, true, 'loader.count-stringed', undefined, { count: count.toString() });

            const newData: Array<StoredObject> = [];

            let invoiceNumber = this._invoiceNumber;
            const services: Array<Tag> = [];
            const availableServices: { [serviceName: string]: number } = {
                'External Windows': 12,
                'Gutters': 180,
                'Internal Windows': 18,
                'Conservatory': 9,
                'Carpet Cleaning': 140,
                'Deep Cleaning': 230,
            };

            for (const serviceName in availableServices) {
                let service = Data.firstOrDefault<Tag>('tags', r => r.type === TagType.SERVICE && r.description === serviceName);
                if (!service) {
                    service = new Tag(serviceName, TagType.SERVICE);
                    (<Service>service).price = availableServices[serviceName];
                    (<Service>service).quantity = 1;
                    await TagService.updateTag(service);
                }

                services.push(service);
            }

            const rounds: Array<JobGroup> = [];
            const names = ['1. Magic Monday', '2. Terrific Tuesday', '3. Wicked Wednesday', '4. Thirsty Thursday', '5. Frantic Friday'];
            for (let i = 0; i <= 4; i++) {
                let round = Data.firstOrDefault<JobGroup>('tags', r => r.type === TagType.ROUND && r.description === names[i]);

                if (!round) {
                    round = new JobGroup(names[i]);
                    await TagService.updateTag(round);
                }
                rounds.push(round);
            }

            const assignees = UserService.getWorkers();

            const dontVerifyCount = Math.ceil(count * 0.05);

            while (count--) {
                const customer = CustomerService.create(undefined, undefined, phone, undefined);
                Data.setUniqueExternalId(customer);
                customer.address.addressDescription = Generator.getRandomAddress();
                customer.name = Generator.getRandomName();
                const randomNameEmailSafe = customer.name.replace(' ', '_').toLowerCase();
                customer.email = email
                    .replace('[customerName]', randomNameEmailSafe)
                    .replace('[customerId]', customer._externalId || randomNameEmailSafe);

                const day = Utilities.randomInteger(0, 4);

                const xOffset = day;
                const yOffset = Utilities.randomInteger(-4, 4);

                if (count >= dontVerifyCount) {
                    customer.address.isVerified = true;

                    customer.address.lngLat = [
                        Number(Math.random() / 10 + 0.1278 - xOffset / 7),
                        Number(Math.random() / 10 + 51.5074 - yOffset / 7),
                    ];
                    customer.address.lngLatSource = 'locationPickerManual';
                }

                const firstDate = moment().add(Utilities.randomInteger(-30, 1), 'weeks').startOf('isoWeek').add(day, 'days');

                const selectedServices: Array<Tag> = [];

                for (const service of services) {
                    if (!Math.floor(Math.random() * 5)) selectedServices.push(Utilities.copyObject(service));
                }

                if (selectedServices.length === 0) selectedServices.push(Utilities.copyObject(services[Math.floor(Math.random() * 5)]));

                const serviceBasedPricing = !!Math.floor(Math.random() * 2);
                if (!serviceBasedPricing) {
                    selectedServices.forEach((s: Service) => {
                        delete s.price;
                        delete s.quantity;
                    });
                }

                const weeks = [4, 6].random();

                const job = new Job(
                    customer._id,
                    undefined,
                    undefined,
                    firstDate.format('YYYY-MM-DD'),
                    customer.address,
                    undefined,
                    '#sample-data',
                    serviceBasedPricing
                        ? selectedServices.reduce(
                              (total: number, service: Service) => total + (service.price || 5) * (service.quantity || 1),
                              0
                          )
                        : Utilities.randomInteger(5, 50),
                    [],
                    weeks,
                    FrequencyType.Weeks,
                    [rounds[day]],
                    selectedServices,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined
                );

                const assignee = (assignees.length && assignees.random()._id) || undefined;
                if (assignee) AssignmentService.assign(job, assignee, false, false);

                customer.jobs[job._id] = job;

                newData.push(customer);

                let nextDate = job.date;
                const paymentMethod = Utilities.chanceValue(0.1, TransactionType.Payment, TransactionType.AutomaticPayment);
                let paymentSubMethod: PaymentTransactionSubType | AutomaticPaymentTransactionSubType;
                if (paymentMethod === TransactionType.AutomaticPayment) {
                    paymentSubMethod = 'auto.go-cardless';
                } else {
                    const paymentMethodSubTypes: Array<PaymentTransactionSubType> = [
                        'payment.cash',
                        'payment.card',
                        'payment.bank-transfer',
                        'payment.stripe',
                    ];
                    paymentSubMethod = paymentMethodSubTypes.random();
                }

                while (nextDate && nextDate < todayIso) {
                    if (!Utilities.chance(0.98)) break;
                    const status = Utilities.chanceValue(0.98, JobOccurrenceStatus.Done, JobOccurrenceStatus.Skipped);
                    const occurrence = new JobOccurrence(job, nextDate, status, nextDate);
                    newData.push(occurrence);

                    if (status === JobOccurrenceStatus.Done) {
                        const invoice = InvoiceService.createInvoiceForCustomer(customer);

                        invoice.date = nextDate;
                        invoice.dueDate = nextDate;

                        invoice.items = InvoiceService.occurrenceToInvoiceItems(
                            occurrence,
                            customer.address.addressDescription !== ((occurrence.location && occurrence.location.addressDescription) || ''),
                            true
                        );
                        Invoice.updateTotals(invoice);

                        const invoiceTransaction = new Transaction(
                            TransactionType.Invoice,
                            'invoice.standard',
                            invoice.total,
                            customer._id,
                            undefined,
                            '',
                            invoice.date,
                            undefined,
                            invoice
                        );

                        invoice.invoiceNumber = invoiceNumber;
                        invoiceNumber++;
                        invoiceTransaction.status = 'apiHandlingComplete';
                        occurrence.invoiceTransactionId = invoiceTransaction._id;
                        occurrence.paymentStatus = 'invoiced';

                        if (Utilities.chance(0.98)) {
                            const payment = new Transaction(
                                paymentMethod,
                                paymentSubMethod,
                                -(job.price || 0),
                                customer._id,
                                job._id,
                                'sample-payment',
                                nextDate
                            );
                            payment.status = paymentMethod === TransactionType.AutomaticPayment ? 'apiHandlingComplete' : 'complete';
                            newData.push(payment);

                            invoice.paymentReference = payment._id;
                            invoice.paid = true;
                            occurrence.paymentTransactionId = payment._id;
                            occurrence.paymentStatus = 'paid';
                            occurrence.paidDate = nextDate;
                        }

                        newData.push(invoiceTransaction);
                    }
                    nextDate = firstDate.add(weeks, 'weeks').format('YYYY-MM-DD');
                }

                if (count > 0 && count % Math.ceil(count / 50) === 0) {
                    new LoaderEvent(true, true, 'loader.count-stringed', undefined, { count: count.toString() });
                    await Data.put(newData.splice(0, newData.length), true, 'lazy');
                    await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));
                }
            }

            Api.setInvoiceNumber(invoiceNumber);
            await Data.put(newData, true, 'lazy');
            this.updateDatabaseSizeText();
            new LoaderEvent(false);
        } catch (error) {
            new Prompt('general.warning', <TranslationKey>'Failed to create the test data.');
            new LoaderEvent(false);
        }
    }

    protected get spotlessWaterMarkersEnabled() {
        return !ApplicationState.spotlessWaterMarkersDisabled;
    }
    protected set spotlessWaterMarkersEnabled(value: boolean) {
        ApplicationState.spotlessWaterMarkersDisabled = !value;
    }

    protected get dontAutoChargeInvoiceMinusCredit() {
        return !ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit;
    }
    protected set dontAutoChargeInvoiceMinusCredit(value: boolean) {
        ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit = !value;
        ApplicationState.save();
    }

    protected get autoChargeInvoiceMinusCreditText() {
        return this.dontAutoChargeInvoiceMinusCredit ? 'dialogs.confirm-auto-payments' : 'dialogs.dont-confirm-auto-payments';
    }

    protected databaseSizeText: string;
    private async updateDatabaseSizeText() {
        const customers = Data.all<Customer>('customers');
        const customerCount = customers.length;
        const jobCount = customers.map(customer => Object.keys(customer.jobs || {}).length).reduce((sum, next) => sum + next, 0);
        let space: string | undefined;
        try {
            const cordova = (<any>window).cordova;
            space = await new Promise<string | undefined>(resolve => {
                setTimeout(() => resolve(undefined), 1000);
                if (cordova && cordova.exec) {
                    cordova.exec(
                        (space: number) => resolve(`${this.mb(space)}`),
                        () => resolve(undefined),
                        'File',
                        'getFreeDiskSpace',
                        []
                    );
                } else return resolve(undefined);
            });

            if (!space) {
                space = await new Promise<string | undefined>(resolve => {
                    setTimeout(() => resolve(undefined), 1000);
                    const storage = (<any>navigator).webkitTemporaryStorage;
                    if (storage && storage.queryUsageAndQuota) {
                        storage.queryUsageAndQuota(
                            (used: number, total: number) =>
                                resolve(
                                    ApplicationState.localise('general.x-of-y-used', {
                                        x: this.mb(used),
                                        y: this.mb(total) + ' device storage',
                                    })
                                ),
                            () => resolve(undefined)
                        );
                    } else return resolve(undefined);
                });
            }
        } catch (error) {
            Logger.error('Error during check free space', error);
        }

        if (space) {
            this.databaseSizeText = `${ApplicationState.localise('settings.database-size', {
                customers: customerCount.toString(),
                jobs: jobCount.toString(),
            })} ${space}`;
        } else {
            this.databaseSizeText = ApplicationState.localise('settings.database-size-space-check-failed', {
                customers: customerCount.toString(),
                jobs: jobCount.toString(),
            });
        }
    }

    protected requestAnImportOrQuote(product?: string) {
        ApplicationState.getSupport(
            product ? `${product} Import Request` : 'Custom Import Quote Request',
            false,
            `Remember to attach your ${product || 'custom'} import files...`
        );
    }

    protected get emailApiDescription() {
        switch (ApplicationState.account.emailAPI) {
            case 'email-engine':
                return 'Squeegee Two Way Email';
            case 'custom-smtp':
                return 'Custom SMTP';
        }
        return 'Squeegee SMTP';
    }

    protected async openEmailSettings() {
        const emailSettingsDialog = new AureliaReactComponentDialog({
            component: EmailSettingsDialog,
            dialogTitle: 'smtp.settings-title',
            componentProps: {},
            isSecondaryView: true,
        });
        await emailSettingsDialog.show();
    }

    protected async configureEmailApi() {
        const smtpDialog = new SmtpDialog();
        await smtpDialog.show();
    }

    private mb(bytes: number) {
        return `${(bytes / 1000000).toFixed(2)}Mb`;
    }

    protected get requireTimerPauseReason() {
        return ApplicationState.getSetting('global.jobs.require-timer-pause-reason', false);
    }

    protected set requireTimerPauseReason(value: boolean) {
        ApplicationState.setSetting('global.jobs.require-timer-pause-reason', value);
    }

    protected updateRequireTimerPauseReason() {
        ApplicationState.setSetting('global.jobs.require-timer-pause-reason', this.requireTimerPauseReason);
    }

    protected predefinedTimerPauseReasons = ApplicationState.getSetting<string>('global.jobs.predefined-timer-pause-reasons', '');
    protected async changePredefinedTimerPauseReasons() {
        const dialog = new TextDialog(
            'dialogs.change-predefined-timer-pause-reasons-title',
            'dialogs.change-predefined-timer-pause-reasons-instructions',
            this.predefinedTimerPauseReasons,
            '',
            undefined,
            false,
            'textarea'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.setSetting('global.jobs.predefined-timer-pause-reasons', result.trim());
            this.predefinedTimerPauseReasons = result.trim();
        }
    }

    @computedFrom('predefinedTimerPauseReasons')
    protected get predefinedTimerPauseReasonsDescription() {
        return Utilities.localiseList(this.predefinedTimerPauseReasons.split('\n').map(x => `"${x}"` as TranslationKey));
    }

    protected get requireSkipReason() {
        return !!ApplicationState.account.requireSkipReason;
    }

    protected set requireSkipReason(value: boolean) {
        ApplicationState.account.requireSkipReason = value ? true : false;
        ApplicationState.save();
    }

    protected defaultSkipChargeText = getDefaultSkipChargeText();
    protected setDefaultSkipJobCharge = () =>
        setDefaultSkipJobCharge().then(() => (this.defaultSkipChargeText = getDefaultSkipChargeText()));

    protected skipReasonsAndChargesText = generateSkipReasonsAndChargesText();
    protected updateSkipReasonsAndCharges = () =>
        updateSkipReasonsAndCharges().then(() => (this.skipReasonsAndChargesText = generateSkipReasonsAndChargesText()));

    protected updateSkills = () => updateSkills();

    protected updateCannedResponses = () => {
        AureliaReactComponentDialog.show({
            component: SettingsCannedResponsesDialog,
            dialogTitle: 'settings.edit-canned-response-dialog-title',
            isSecondaryView: true,
        });
    };

    protected get requireDeactivateDeleteCustomerReason() {
        return ApplicationState.getSetting('global.require-deactivate-delete-customer-reasons', false);
    }

    protected set requireDeactivateDeleteCustomerReason(value: boolean) {
        ApplicationState.setSetting('global.require-deactivate-delete-customer-reasons', value);
    }

    protected requireDeactivateDeleteCustomerReasonChanged = () => {
        ApplicationState.setSetting('global.require-deactivate-delete-customer-reasons', !!this.requireDeactivateDeleteCustomerReason);
    };

    protected predefinedDeactivateDeleteCustomerReasons = Utilities.localiseList(
        ApplicationState.getSetting('global.deactivate-delete-customer-reasons', '')
            .split('\n')
            .filter(x => !!x)
            .map(x => `"${x}"` as TranslationKey)
    );

    public async changeDeactivateDeleteCustomerReason() {
        const dialog = new TextDialog(
            'Customer Deactivate/Delete Reasons' as TranslationKey,
            'Add or update predefined customer deactivate/delete reasons, one per line.' as TranslationKey,
            ApplicationState.getSetting('global.deactivate-delete-customer-reasons', ''),
            '',
            undefined,
            false,
            'textarea'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            const reasons = result
                .split('\n')
                .filter(x => !!x)
                .join('\n')
                .trim();
            ApplicationState.setSetting('global.deactivate-delete-customer-reasons', reasons);
            this.predefinedDeactivateDeleteCustomerReasons = Utilities.localiseList(
                reasons
                    .split('\n')
                    .filter(x => !!x)
                    .map(x => `"${x}"` as TranslationKey)
            );
        }
    }

    public async changeNotificationBCCEmail() {
        const dialog = new TextDialog(
            'dialogs.settings-notification-bcc-title',
            'dialogs.settings-notification-bcc-label',
            ApplicationState.account.bccEmailNotifications,
            'dialogs.settings-notification-bcc-title',
            validateEmailList
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.bccEmailNotifications = result.trim();
            ApplicationState.save();
        }
    }

    public async changeInvoiceEmail() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-email-title',
            'dialogs.settings-invoice-email-label',
            ApplicationState.account.invoiceSettings.email || ApplicationState.account.email,
            'dialogs.settings-invoice-email-title',
            validateEmailList
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.email = result.trim();
            ApplicationState.save();
        }
    }

    public get autoInvoiceNotification() {
        return ApplicationState.account.invoiceSettings.defaultAutoInvoiceNotification === 'auto';
    }

    public set autoInvoiceNotification(value: boolean) {
        ApplicationState.account.invoiceSettings.defaultAutoInvoiceNotification = value ? 'auto' : 'manual';
        this.save();
    }

    public updateHideStripePayButtonFromInvoice() {
        ApplicationState.account.invoiceSettings.hideStripePayButtonFromInvoice = this.hideStripePayButtonFromInvoice ? true : false;
        this.save();
    }

    public updateHideGoCardlessSignupFromInvoice() {
        ApplicationState.account.invoiceSettings.hideGoCardlessSignupFromInvoice = this.hideGoCardlessSignupFromInvoice ? true : false;
        this.save();
    }

    protected get hidePricesFromWorkerRole() {
        return !!ApplicationState.account.hidePricesFromWorkerRole;
    }

    protected set hidePricesFromWorkerRole(value: boolean) {
        ApplicationState.account.hidePricesFromWorkerRole = value;
        this.save();
    }

    protected get hideContactFromWorkersAndPlanners() {
        return ApplicationState.getSetting('global.worker-planner-hide-contact-details', false);
    }

    protected set hideContactFromWorkersAndPlanners(value: boolean) {
        ApplicationState.setSetting('global.worker-planner-hide-contact-details', value);
    }

    protected get hideCustomerNotesFromWorkersAndPlanners() {
        return !!ApplicationState.account.hideCustomerNotesFromWorkersAndPlanners;
    }

    protected set hideCustomerNotesFromWorkersAndPlanners(value: boolean) {
        ApplicationState.account.hideCustomerNotesFromWorkersAndPlanners = value;
        this.save();
    }

    protected get showOwingToWorkers() {
        return !!ApplicationState.account.showOwingToWorkers;
    }

    protected set showOwingToWorkers(value: boolean) {
        ApplicationState.account.showOwingToWorkers = value;
        this.save();
    }

    protected get dayPilotDayIsSticky() {
        return !!ApplicationState.account.dayPilotDayIsSticky;
    }

    protected set dayPilotDayIsSticky(value: boolean) {
        ApplicationState.account.dayPilotDayIsSticky = value;
        this.save();
    }

    protected get requireSignature() {
        return !!ApplicationState.account.requireSignature;
    }

    protected set requireSignature(value: boolean) {
        ApplicationState.account.requireSignature = value;
        this.save();
    }

    protected get dayPilotDefaultOptimise() {
        return ApplicationState.account.dayPilotDefaultOptimise;
    }

    protected set dayPilotDefaultOptimise(value: boolean) {
        ApplicationState.account.dayPilotDefaultOptimise = value;
        this.save();
    }

    protected get updateScheduleOnReplan() {
        return ApplicationState.account.schedulingBehavior === 'update-schedule';
    }

    protected set updateScheduleOnReplan(value: boolean) {
        ApplicationState.account.schedulingBehavior = value ? 'update-schedule' : <any>null;
        this.save();
    }

    protected get stickyChartArea() {
        return !!ApplicationState.account.stickyChartArea;
    }

    protected set stickyChartArea(value: boolean) {
        ApplicationState.account.stickyChartArea = value;
        this.save();
    }

    public updateAutoTakePaymentOnInvoiced() {
        ApplicationState.account.defaultAutoPaymentCollection = this.defaultAutoPaymentCollection;
        this.save();
    }

    public togglePreventMultiplePaymentsInSameDay() {
        if (!ApplicationState.account.paymentSettings)
            ApplicationState.account.paymentSettings = { preventMultiplePaymentsInSameDay: this.preventMultiplePaymentsInSameDay || false };
        else ApplicationState.account.paymentSettings.preventMultiplePaymentsInSameDay = this.preventMultiplePaymentsInSameDay || false;
        this.save();
    }

    public async changeInvoicePhone() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-phone-title',
            'dialogs.settings-invoice-phone-label',
            ApplicationState.account.invoiceSettings.phone,
            'dialogs.settings-invoice-phone-title'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.phone = result.trim();
            ApplicationState.save();
        }
    }
    public async changeInvoiceDefaultNotes() {
        const notes =
            (ApplicationState.account.invoiceSettings.defaultNotes && ApplicationState.account.invoiceSettings.defaultNotes) || '';
        const dialog = new TextDialog(
            'dialogs.settings-invoice-notes-title',
            'dialogs.settings-invoice-notes-label',
            notes,
            'dialogs.settings-invoice-notes-title',
            undefined,
            false,
            'textarea'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.defaultNotes = result.trim();
            ApplicationState.save();
        }
    }

    public async changeInvoiceWebsite() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-website-title',
            'dialogs.settings-invoice-website-label',
            ApplicationState.account.invoiceSettings.website,
            'dialogs.settings-invoice-website-title'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.website = result.trim();
            ApplicationState.save();
        }
    }

    protected get stickyMultiviewWorkload() {
        return ApplicationState.getSetting<boolean>('global.scheduling.sticky-multiview-workload', undefined) !== undefined;
    }

    protected set stickyMultiviewWorkload(value: boolean) {
        ApplicationState.setSetting('global.scheduling.sticky-multiview-workload', value);
        this.save();
    }

    protected get autoChooseNavMethod() {
        return ApplicationState.getSetting<boolean>('global.scheduling.auto-choose-nav-method', false);
    }

    protected set autoChooseNavMethod(value: boolean) {
        ApplicationState.setSetting('global.scheduling.auto-choose-nav-method', value);
        this.save();
    }

    protected myWorkVisibleDays = ApplicationState.getSetting<number>('global.scheduling.mywork-days-visible-length', undefined);

    protected get myWorkVisibleDaysEnabled() {
        return ApplicationState.getSetting('global.scheduling.mywork-days-visible-length', undefined) !== undefined;
    }

    protected set myWorkVisibleDaysEnabled(value: boolean) {
        if (value) {
            ApplicationState.setSetting('global.scheduling.mywork-days-visible-length', 365);
        } else if (!value) {
            ApplicationState.clearSetting('global.scheduling.mywork-days-visible-length');
        }
    }

    private _visibleDaysText = t('settings.mywork-visible-days-text', {
        days: this.myWorkVisibleDays === undefined ? '365' : this.myWorkVisibleDays,
    });
    protected get visibleDaysText() {
        return this._visibleDaysText;
    }

    public async enterMyWorkVisibleDays() {
        const dialog = new TextDialog(
            'dialogs.settings-mywork-days-visible-title',
            'dialogs.settings-mywork-days-visible-label',
            this.myWorkVisibleDays?.toString() || '',
            'dialogs.settings-mywork-days-visible-title',
            value =>
                value.length !== 0 && (isNaN(parseFloat(value)) || parseFloat(value) < 0 || parseFloat(value) > 365)
                    ? 'validation.mywork-days-visible-invalid'
                    : true,
            false,
            'number'
        );

        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            // if they left the prompt blank, unlimited visibility (undefined setting)
            if (result.length === 0) return await ApplicationState.clearSetting('global.scheduling.mywork-days-visible-length');

            await ApplicationState.setSetting('global.scheduling.mywork-days-visible-length', result);
            this.myWorkVisibleDays = Number(result) || 365;
            this._visibleDaysText = t('settings.mywork-visible-days-text', { days: result });
        }
    }

    public async selectTaxRate() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-tax-rate-title',
            'dialogs.settings-invoice-tax-rate-label',
            (ApplicationState.account.invoiceSettings.taxRate || 0).toString(),
            'dialogs.settings-invoice-tax-rate-title',
            value => (isNaN(parseFloat(value)) || parseFloat(value) <= 0 ? 'validation.tax-rate-invalid' : true),
            false,
            'number'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.taxRate = parseFloat(result);
            ApplicationState.save();
        }
    }

    public async selectExpenseTaxRate() {
        const dialog = new TextDialog(
            'dialogs.settings-expense-tax-rate-title',
            'dialogs.settings-expense-tax-rate-label',
            (ApplicationState.account.expenseSettings?.taxRate || 0).toString(),
            'dialogs.settings-expense-tax-rate-title',
            value => (isNaN(parseFloat(value)) || parseFloat(value) <= 0 ? 'validation.tax-rate-invalid' : true),
            false,
            'number'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            const expenseSettings = ApplicationState.account.expenseSettings || new AccountExpenseSettings();
            expenseSettings.taxRate = parseFloat(result);
            ApplicationState.account.expenseSettings = expenseSettings;
            ApplicationState.save();
        }
    }

    public get expenseTaxEnabled() {
        return !!ApplicationState.account.expenseSettings?.taxEnabled;
    }

    public set expenseTaxEnabled(value: boolean) {
        const expenseSettings = ApplicationState.account.expenseSettings || new AccountExpenseSettings();
        expenseSettings.taxEnabled = value;
        ApplicationState.account.expenseSettings = expenseSettings;
        ApplicationState.save();
    }

    public get invoiceTaxEnabled() {
        return !!ApplicationState.account.invoiceSettings?.taxEnabled;
    }

    public set invoiceTaxEnabled(value: boolean) {
        const invoiceSettings = ApplicationState.account.invoiceSettings || new AccountInvoiceSettings();
        invoiceSettings.taxEnabled = value;
        ApplicationState.account.invoiceSettings = invoiceSettings;
        ApplicationState.save();
    }

    public get invoiceHideTax() {
        return !!ApplicationState.account.invoiceSettings?.hideTax;
    }

    public set invoiceHideTax(value: boolean) {
        const invoiceSettings = ApplicationState.account.invoiceSettings || new AccountInvoiceSettings();
        invoiceSettings.hideTax = value;
        ApplicationState.account.invoiceSettings = invoiceSettings;
        ApplicationState.save();
    }

    public get priceIncludesTax() {
        return !!ApplicationState.account.invoiceSettings?.priceIncludesTax;
    }

    public set priceIncludesTax(value: boolean) {
        const invoiceSettings = ApplicationState.account.invoiceSettings || new AccountInvoiceSettings();
        invoiceSettings.priceIncludesTax = value;
        ApplicationState.account.invoiceSettings = invoiceSettings;
        ApplicationState.save();
    }

    public async changeTaxLabel() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-tax-label-title',
            'dialogs.settings-invoice-tax-label-label',
            ApplicationState.account.invoiceSettings.taxLabel || 'VAT',
            'dialogs.settings-invoice-tax-label-title'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.taxLabel = result.trim();
            ApplicationState.save();
        }
    }

    public async changeTaxNumber() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-tax-number-title',
            'dialogs.settings-invoice-tax-number-label',
            ApplicationState.account.invoiceSettings.taxNumber,
            'dialogs.settings-invoice-tax-number-title'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.taxNumber = result.trim();
            ApplicationState.save();
        }
    }

    protected hasTaxLedger = !!Data.get(ApplicationState.dataEmail + '-tax');

    public enableTaxLedger() {
        AccountsLedgerService.enableVATLedger();
        this.hasTaxLedger = !!Data.get(ApplicationState.dataEmail + '-tax');
    }

    protected hasDebtorsLedger = !!Data.get(ApplicationState.dataEmail + '-debtors');

    public enableDebtorsLedger() {
        AccountsLedgerService.enableDebtorsLedger();
        this.hasDebtorsLedger = !!Data.get(ApplicationState.dataEmail + '-debtors');
    }

    protected hasCreditorsLedger = !!Data.get(ApplicationState.dataEmail + '-creditors');

    public enableCreditorsLedger() {
        AccountsLedgerService.enableCreditorsLedger();
        this.hasDebtorsLedger = !!Data.get(ApplicationState.dataEmail + '-creditors');
    }

    public async changeInvoicePrefix() {
        const dialog = new TextDialog(
            'settings.invoice-prefix',
            'settings.invoice-prefix-text',
            ApplicationState.account.invoiceSettings.invoicePrefix || '',
            'settings.invoice-prefix'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.account.invoiceSettings.invoicePrefix = result.trim();
            ApplicationState.save();
        }
    }

    public async setDefaultJobDuration() {
        const dialog = new DurationDialog(this.defaultJobDuration);
        const result = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationState.account.defaultJobDuration = result;
            this.defaultJobDuration = result;
            ApplicationState.save();
        }
    }

    public async setDefaultNotificationMethod() {
        const options: Array<{ text: TranslationKey; value: string }> = [{ text: 'general.none', value: '' }];
        options.push({ text: 'customers.notification-email-label', value: 'email' });
        options.push({ text: 'customers.notification-phone-label', value: 'sms' });
        options.push({ text: 'customers.notification-alternative-phone-label', value: 'sms2' });
        options.push({ text: 'customers.notification-email-and-phone-label', value: 'email-and-sms' });
        options.push({ text: 'customers.notification-email-and-phone2-label', value: 'email-and-sms2' });

        const dialog = new Select(
            'dialogs.default-notification-method-title',
            options,
            'text',
            'value',
            ApplicationState.account.defaultNotificationMethod
        );
        const result = await dialog.show(DialogAnimation.SLIDE);

        if (!dialog.cancelled) {
            if (result.value) {
                ApplicationState.account.defaultNotificationMethod = <CustomerNotificationMethodTypes>result.value;
            } else ApplicationState.account.defaultNotificationMethod = <any>null;
            this.defaultNotificationMethod = ApplicationState.account.defaultNotificationMethod;
            ApplicationState.save();
        }
    }

    public async updateCredits() {
        const creditsUpdate = await Api.getSqueegeeCredits();
        this._squeegeeCredits = creditsUpdate?.squeegeeCredits;
        this._squeegeeCreditsNextRefresh = creditsUpdate?.nextRefresh;
    }

    public async setSmsSettings() {
        await presentSmsSettings();
        this.updateCredits();
    }

    protected get isUsingSqueegeeSms() {
        const isUsingSqueegeeSms = this.account.smsAPI === 'squeegee-sms' || this.account.smsAPI === 'squeegee-sms-two-way';
        return isUsingSqueegeeSms;
    }

    protected _squeegeeCredits?: number;
    protected get squeegeeCredits() {
        if (this._squeegeeCredits === undefined) return 'loading...';

        const credits = this._squeegeeCredits || 0;
        const privateNumber =
            (ApplicationState.account.smsAPI === 'squeegee-sms-two-way-private' &&
                ApplicationState.getSetting<string>('global.sms-from-private-number', '')) ||
            '';
        return `${privateNumber}. ${credits} Squeegee Credit${credits === 1 ? '' : 's'} remaining. ${
            this._squeegeeCreditsNextRefresh || ''
        }`;
    }

    protected get apiEndpointText() {
        return `${Api.apiEndpoint} (${ApplicationState.apiEndpointOverride ? 'overridden' : 'auto'})`;
    }

    protected async setSqueegeeApiEndpoint() {
        const dialog = new DeveloperLaunchOptionsDialog();
        await dialog.show(DialogAnimation.SLIDE);
    }

    protected async viewPaymentProviders() {
        const dialog = new ConnectedServicesDialog();
        await dialog.show(DialogAnimation.SLIDE);
    }

    protected async customImport() {
        const availableImporters = Object.keys(CustomImporters).map(x => {
            return { text: <TranslationKey>x, value: x };
        });

        const chooseImporterDialog = new Select('general.select', availableImporters, 'text', 'value');
        const chosenImporter = await chooseImporterDialog.show();
        try {
            if (chooseImporterDialog.cancelled) return;

            const howTo = `<div style="white-space: pre-wrap;">${await (<any>CustomImporters)[chosenImporter.value]()}</div>`;
            if (
                !(await new Prompt('import.options-title', <TranslationKey>howTo, {
                    okLabel: 'general.ok',
                    cancelLabel: 'general.cancel',
                    coverViewport: true,
                    smallerOnDesktop: true,
                }).show())
            )
                return;

            const dialog = new FileUploadDialog(['.csv'], 'uploads.csv-import-file');
            const files = await dialog.show();
            if (!files || !files.length) return;

            const file = files[0];
            const csv = await Utilities.readFileAsString(file);

            new LoaderEvent(true);

            await (<any>CustomImporters)[chosenImporter.value](csv);

            new LoaderEvent(false);

            await new Prompt('general.complete', 'prompts.import-complete-text').show();
        } catch (error) {
            new LoaderEvent(false);
            Logger.error('Failed during custom import', { error });
            const description = `Failed during "${chosenImporter}" custom import.`;
            new Prompt('general.warning', description as TranslationKey, {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
        }
    }

    private _invoiceNumber: number;
    protected get nextInvoiceNumber() {
        return this._invoiceNumber === undefined ? 'unknown' : this._invoiceNumber;
    }

    private async updateInvoiceNumber() {
        const invoiceNumberRequest = await Api.get<{ invoiceNumber: number }>(null, '/api/payments/invoice-number');
        const invoiceNumber = invoiceNumberRequest && invoiceNumberRequest.data && invoiceNumberRequest.data.invoiceNumber;

        if (invoiceNumber === undefined) return;
        this._invoiceNumber = invoiceNumber + 1;
    }

    protected async setInvoiceNumber() {
        if (!(await ApplicationState.onlineRequiredCheck('Setting the invoice number' as TranslationKey))) return;

        const invoiceNumberRequest = await Api.get<{ invoiceNumber: number }>(null, '/api/payments/invoice-number');
        let invoiceNumber = invoiceNumberRequest && invoiceNumberRequest.data && invoiceNumberRequest.data.invoiceNumber;

        if (invoiceNumber === undefined)
            return new Prompt('general.warning', 'problem.getting-current-invoice-number', {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
        invoiceNumber++;
        const numpad = new NumpadDialog(invoiceNumber, undefined, undefined, true);

        invoiceNumber = await numpad.show();

        if (numpad.cancelled) return;

        const description = `Are you sure you set the next invoice number to ${invoiceNumber}, this will affect all invoices going forward?`;
        if (await new Prompt('general.confirm', description as TranslationKey).show()) {
            invoiceNumber--;
            await Api.setInvoiceNumber(invoiceNumber);
            await this.updateInvoiceNumber();
        }
    }

    public async changeMessageTemplate(templateName: keyof MessageTemplates & keyof AccountMessagingTemplates) {
        const messagingTemplates = ApplicationState.messagingTemplates;
        const dialogProperties = MessageTemplates.instance[templateName];

        if (dialogProperties) {
            const possibleTemplate = messagingTemplates[templateName];
            const currentTemplate = typeof possibleTemplate === 'string' ? possibleTemplate : undefined;
            const isSms = templateName.startsWith('sms');
            const liveInfoMethod = isSms
                ? (value: string) => Utilities.getSmsLengthText(value)
                : (value: string) => Utilities.getEmailLengthText(value);

            const dialog = new TextDialog(
                dialogProperties.title,
                dialogProperties.instructions,
                currentTemplate || dialogProperties.default || '',
                'message-templates.enter-a-default',
                undefined,
                false,
                isSms ? 'textarea' : 'tinymce',
                undefined,
                undefined,
                liveInfoMethod,
                isSms ? 1224 : undefined
            );
            const result = await dialog.show(DialogAnimation.SLIDE);
            if (!dialog.cancelled) {
                messagingTemplates[templateName] = result.trim();
                if (!isSms) (<any>messagingTemplates)[(templateName + 'IsHtml') as keyof AccountMessagingTemplates] = true;
                ApplicationState.save();
            }
        }
    }

    public async changeSmsBookingConfirmation() {
        const currentValue = ApplicationState.getSetting(
            'global.sms-booking-template',
            ApplicationState.localise('booking.default-sms-template', { items: '[items]', businessName: '[businessName]' })
        );
        const liveInfoMethod = (value: string) => Utilities.getSmsLengthText(value);

        const instructions = `${ApplicationState.localise(
            'message-templates.instructions'
        )} ${bookingConfirmationTokenList} ` as TranslationKey;

        const dialog = new TextDialog(
            'message-templates.sms-booking-confirmation-title',
            instructions,
            currentValue,
            'message-templates.enter-a-default',
            undefined,
            false,
            'textarea',
            undefined,
            undefined,
            liveInfoMethod,
            1224
        );
        const result = await dialog.show(DialogAnimation.SLIDE);

        if (!dialog.cancelled) {
            const value = result.trim();
            if (value) {
                ApplicationState.setSetting('global.sms-booking-template', value);
            }
        }
    }

    public showOnboardingDialog() {
        SlideGenerator.showOnboardingSlides(true);
    }

    protected async loaderTest() {
        new LoaderEvent(true);
        await wait(60000);
        new LoaderEvent(false);
    }

    protected async refreshPendingStatuses() {
        new LoaderEvent(true, true, 'loader.refreshing-pending-automatic-payments-and-customers');
        await wait(50);
        try {
            await ConnectedServicesService.refreshPendingAutomaticPaymentsAndCustomers();
        } finally {
            new LoaderEvent(false);
        }
    }

    public async runAllocationOnData() {
        new LoaderEvent(true, true, 'loader.refreshing-invoice-payment-and-job-completion');
        await wait(50);
        try {
            const customerIds: Array<string> = Data.all('customers').map(x => x._id);

            for (const customerId of customerIds) {
                await InvoiceService.allocateBalanceToInvoices(customerId);
            }

            Logger.info('Ran allocation fix on ' + customerIds.length);
        } finally {
            new LoaderEvent(false);
        }
    }

    private _customPaymentMethods: Record<string, string>;
    private _customPaymentMethodsArray: Array<{ paymentMethodGuid: string; name: string }>;
    protected get customPaymentMethodsArray() {
        if (this._customPaymentMethods === undefined) this.initCustomPaymentMethods();
        return this._customPaymentMethodsArray;
    }

    private initCustomPaymentMethods() {
        this._customPaymentMethods = ApplicationState.getSetting('global.custom-payment-methods', {} as Record<string, string>);
        this._customPaymentMethodsArray = Object.keys(this._customPaymentMethods)
            .map(x => ({ paymentMethodGuid: x, name: this._customPaymentMethods[x] }))
            .sort((x, y) => (x.name > y.name ? 1 : x.name < y.name ? -1 : 0));
    }

    protected async addOrUpdateCustomPaymentMethod(existing?: { paymentMethodGuid: string; name: string }) {
        const nameDialog = new TextDialog(
            'Name' as TranslationKey,
            'Please enter a name for this payment method.' as TranslationKey,
            existing?.name || '',
            '',
            value =>
                !value || value.length < 3 || value.length > 20
                    ? ('Payment method name is required and must be between 3 and 20 characters long.' as TranslationKey)
                    : this._customPaymentMethodsArray.some(x => x.name === value.trim())
                    ? ('There is already a custom payment method with this name.' as TranslationKey)
                    : true
        );

        await nameDialog.show();
        if (nameDialog.cancelled) return;

        if (!existing) existing = { paymentMethodGuid: `payment.custom-${uuid()}`, name: nameDialog.value.trim() };
        else existing.name = nameDialog.value.trim();

        const methods = this._customPaymentMethods;
        methods[existing.paymentMethodGuid] = existing.name;
        ApplicationState.setSetting('global.custom-payment-methods', methods);

        this.initCustomPaymentMethods();
    }

    protected async deleteCustomPaymentMethod(toDelete: { paymentMethodGuid: string; name: string }) {
        const existingPayments = Data.count<Transaction>('transactions', {
            transactionSubType: toDelete.paymentMethodGuid as TransactionSubType,
        });
        if (existingPayments > 0) {
            new NotifyUserMessage(
                'This custom payment type cannot be deleted as payments of this type have been created.' as TranslationKey
            );
            return;
        }

        if (!(await Prompt.continue('Are you sure you wish to delete this custom payment method?' as TranslationKey))) return;

        const methods = this._customPaymentMethods;
        delete methods[toDelete.paymentMethodGuid];
        ApplicationState.setSetting('global.custom-payment-methods', methods);

        this.initCustomPaymentMethods();
    }

    public async configureWorkerPlannerAllowedPaymentMethods() {
        const allMethods = getPaymentMethods(false, undefined, true).map(x => ({ id: x.id, name: x.name }));

        const methods = allMethods.map(x => ({ id: x.id, name: x.name }));

        const allowedMethodsSetting = ApplicationState.getSetting<AllowedPaymentTypes>('global.allowed-worker-planner-payment-methods');

        const allowedMethods = allowedMethodsSetting
            ? allowedMethodsSetting.map(x => methods.find(y => y.id === x)).filter(notNullUndefinedEmptyOrZero)
            : methods.filter(x => DefaultWorkerPaymentMethods.some(y => y === x.id));

        const selectAllowedMethods = new SelectMultiple(
            'Select Allowed Payment Methods' as TranslationKey,
            methods,
            'name',
            'id',
            allowedMethods
        );

        await selectAllowedMethods.show();

        if (selectAllowedMethods.cancelled) return;

        ApplicationState.setSetting(
            'global.allowed-worker-planner-payment-methods',
            selectAllowedMethods.selectedOptions.map(x => x.id)
        );
    }

    public async resetOrdering() {
        const jobOrderData = Data.all('joborderdata').slice();
        await Data.delete(jobOrderData);
        await ScheduleService.initialisePlannedOrder(true);
    }

    protected switchTo(productLevel: ProductLevel) {
        ApplicationState.updateSubscription({ product: { name: 'temp plan', summary: '', features: [], productLevel } });
        let level = 'Unknown Subscription';
        switch (productLevel) {
            case ProductLevel.NotSubscribed:
                level = 'Unsubscribed';
                break;
            case ProductLevel.Core:
                level = 'Squeegee Core';
                break;
            case ProductLevel.Advanced:
                level = 'Squeegee Advanced';
                break;
            case ProductLevel.Ultimate:
                level = 'Squeegee Ultimate';
                break;
        }

        const message = `Temporarily switched to ${level}, reset next time the app is reloaded.`;
        new NotifyUserMessage(message as TranslationKey);
    }

    protected get hideDistanceMarkers() {
        return ApplicationState.hideDistanceMarkers;
    }
    protected set hideDistanceMarkers(value: boolean) {
        ApplicationState.hideDistanceMarkers = value;
    }

    private _disableSwiping: boolean;
    protected get disableSwiping() {
        if (this._disableSwiping === undefined) {
            this._disableSwiping = ApplicationState.getSetting('global.disable-swiping', false);
        }
        return this._disableSwiping;
    }
    protected set disableSwiping(value: boolean) {
        ApplicationState.setSetting('global.disable-swiping', value);
        this._disableSwiping = value;
    }

    protected get workerClock() {
        return ApplicationState.getSetting('global.jobs.worker-clock', false);
    }
    protected set workerClock(value: boolean) {
        ApplicationState.setSetting('global.jobs.worker-clock', value);
    }

    protected get jobOrderLock() {
        return ApplicationState.getSetting('global.lock-job-order-to-admins-only', false);
    }

    protected set jobOrderLock(value: boolean) {
        ApplicationState.setSetting('global.lock-job-order-to-admins-only', value);
    }

    protected updateJobOrderLock() {
        ApplicationState.setSetting('global.lock-job-order-to-admins-only', this.jobOrderLock);
    }

    protected nearestJobInRound = ApplicationState.getSetting('global.use-nearest-job-in-round', true);
    protected updateNearestJobInRound() {
        ApplicationState.setSetting('global.use-nearest-job-in-round', this.nearestJobInRound);
    }

    protected get trackTime() {
        return ApplicationState.getSetting('global.jobs.track-time', false);
    }

    protected set trackTime(value: boolean) {
        ApplicationState.setSetting('global.jobs.track-time', value);
    }

    protected updateTrackTime() {
        ApplicationState.setSetting('global.jobs.track-time', this.trackTime);
    }

    protected timeBillingBlockSize = ApplicationState.getSetting('global.invoicing.time-billing-block-size-minutes', 60);
    protected async selectTimeBillingBlockSize() {
        const dialog = new TextDialog(
            'dialogs.settings-time-billing-block-size-title',
            'dialogs.settings-time-billing-block-size-label',
            this.timeBillingBlockSize.toString(),
            'dialogs.settings-time-billing-block-size-title',
            value =>
                isNaN(parseFloat(value)) || parseFloat(value) <= 0 || parseFloat(value) > 60 || parseFloat(value) % 1 !== 0
                    ? 'validation.time-billing-block-size-invalid'
                    : true,
            false,
            'number'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            ApplicationState.setSetting('global.invoicing.time-billing-block-size-minutes', parseInt(result));
            this.timeBillingBlockSize = parseInt(result);
            ApplicationState.save();
        }
    }

    protected get hideGocardlessFromInvites() {
        return !!ApplicationState.getSetting('global.gocardless.hide-from-invite', false);
    }

    protected set hideGocardlessFromInvites(value: boolean) {
        ApplicationState.setSetting('global.gocardless.hide-from-invite', value);
    }

    protected updateHideGocardlessFromInvites() {
        ApplicationState.setSetting('global.gocardless.hide-from-invite', this.hideGocardlessFromInvites);
    }
    protected get hideStripeFromInvites() {
        return ApplicationState.getSetting('global.stripe.hide-from-invite', false);
    }

    protected set hideStripeFromInvites(value: boolean) {
        ApplicationState.setSetting('global.stripe.hide-from-invite', value);
    }

    protected updateHideStripeFromInvites() {
        ApplicationState.setSetting('global.stripe.hide-from-invite', this.hideStripeFromInvites);
    }

    protected get resetOccurrencesOnDone() {
        return ApplicationState.getSetting('global.jobs.reset-occurrences-on-done', false);
    }

    protected set resetOccurrencesOnDone(value: boolean) {
        ApplicationState.setSetting('global.jobs.reset-occurrences-on-done', value);
        this.save();
    }

    protected schedulePlannedOnly = ApplicationState.getSetting('global.jobs.schedule-planned-only', false);
    protected updateResetOccurrencesOnDone() {
        ApplicationState.setSetting('global.jobs.reset-occurrences-on-done', this.resetOccurrencesOnDone);
    }

    protected get reassignIfTeamAssigned() {
        return ApplicationState.getSetting('global.jobs.reassign-if-team-assigned', false);
    }

    protected set reassignIfTeamAssigned(value: boolean) {
        ApplicationState.setSetting('global.jobs.reassign-if-team-assigned', value);
        this.save();
    }

    protected updatedSchedulePlannedOnly() {
        ApplicationState.setSetting('global.jobs.schedule-planned-only', this.schedulePlannedOnly);
    }

    protected showAllLocalStorageValues() {
        try {
            new Prompt('Local Storage Data' as TranslationKey, JSON.stringify(localStorage) as TranslationKey, { cancelLabel: '' });
        } catch (error) {
            Logger.error('Failed to grab the local storage data.', error);
        }
    }

    protected async deleteAccount() {
        AccountActions.processAccountDelete();
    }

    protected async accountingPeriods() {
        const dialog = new AccountingPeriodSettingsDialog();
        await dialog.show();
    }

    protected get hideFromAccountsList() {
        return !!ApplicationState.account.hideFromAccountsList;
    }

    protected set hideFromAccountsList(value: boolean) {
        ApplicationState.account.hideFromAccountsList = value;
        ApplicationState.save();
    }

    protected alertRetentionWeeks = ApplicationState.getSetting<number>('global.alert-retention-weeks', 2);

    protected configureAlertRetention = async () => {
        const options = [
            { text: t('general.1-week'), value: 1 },
            { text: `${t('general.x-weeks', { weeks: 2 })} (${t('general.recommended')})`, value: 2 },
            { text: t('general.x-weeks', { weeks: 4 }), value: 4 },
            { text: t('general.x-weeks', { weeks: 8 }), value: 8 },
        ];

        const dialog = new Select('settings.configure-alert-retention-period-title', options, 'text', 'value', this.alertRetentionWeeks);
        const result = await dialog.show();
        if (dialog.cancelled) return;

        ApplicationState.setSetting('global.alert-retention-weeks', result.value);
        this.alertRetentionWeeks = result.value;
    };

    protected importFromGreenCleen = importFromGreenCleen;

    protected allowAvailabilityBarTypeSwitching =
        ApplicationState.features.availability && ApplicationState.userAuthorisation.roles?.includes('Admin' || 'Owner');

    protected async reportClicksendNumberPool() {
        try {
            const response = await Api.get<{ success: boolean; data: Array<string> }>(
                Api.apiEndpoint,
                '/api/clicksend/number-pool/reportPoolToClient'
            );
            if (!response) return new NotifyUserMessage('Clicksend number pool: no response' as TranslationKey);
            if (!response.data) return new NotifyUserMessage('Clicksend number pool: no data' as TranslationKey);
            if (!response.data.success) return new NotifyUserMessage('Clicksend number pool: failed' as TranslationKey);

            await prompt('Clicksend number pool' as TranslationKey, response.data.data.join(', ') as TranslationKey).show();
        } catch (error) {
            Logger.error('Error during reportClicksendNumberPool', { error });
        }
    }

    protected async clearClicksendNumberPool() {
        try {
            await Api.post(Api.apiEndpoint, '/api/clicksend/number-pool/clearPool');
        } catch (error) {
            Logger.error('Error during clearClicksendNumberPool', { error });
        }
    }

    protected async addFakeNumberToPool() {
        try {
            const phoneNumber = await new TextDialog(
                'Add Fake Number to Pool' as TranslationKey,
                'Phone Number' as TranslationKey,
                '',
                'inputs.contact-number'
            ).show();

            const request = await Api.post<{ success: true; message: string } | { success: false; error: string }>(
                Api.apiEndpoint,
                '/api/clicksend/number-pool/addFakeNumberToPool',
                phoneNumber && { phoneNumber }
            );
            if (!request) return new NotifyUserMessage('Failed to add fake number to pool' as TranslationKey);

            if (request.data.success) {
                new NotifyUserMessage(request.data.message as TranslationKey);
            } else {
                new NotifyUserMessage(request.data.error as TranslationKey);
            }
        } catch (error) {
            Logger.error('Error during addFakeNumberToPool', { error });
        }
    }

    public async importics() {
        const dialog = new FileUploadDialog(['.ics'], 'Upload!!!' as TranslationKey, 2e6, false);
        const files = await dialog.show();

        if (!dialog.cancelled && files && files.length) {
            new LoaderEvent(true, true, 'imports.ics-in-progress');
            try {
                for (const file of files) {
                    const text = await file.text();
                    await Api.post<{ data: { customers: Array<Customer> } }>(null, '/api/import/icsfile', { text });
                    await wait(1000);
                }
            } finally {
                new LoaderEvent(false);
            }
        }
    }
}
